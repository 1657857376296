import React from 'react'

function Header(props) {
  console.log("PRINT PROPSSSSSS");
  console.log(props);
  console.log(props.image_logo);
  return (
    <div className="header">
      <a href="/user"><img src={props.image_logo} alt="" /></a>
      <nav className="header__menu">
        {(props.app_name == "solar_landscape") ? (
          <ul className="header__menuItems">
            <a href="/user"><li className="header__menuItem header__menuItemSL">Home</li></a>
            <a href="https://modernrenewablesnj.com/#whatiscommunitysolar"><li className="header__menuItem header__menuItemSL">How Community Solar Works</li></a>
            <a href="https://modernrenewablesnj.com/#aboutus"><li className="header__menuItem header__menuItemSL">About Us</li></a>
            <a href="https://modernrenewablesnj.com/faq/"><li className="header__menuItem header__menuItemSL">FAQ</li></a>
            <a href="https://modernrenewablesnj.com/contact-us/"><li className="header__menuItem header__menuItemSL">Contact Us</li></a>
            <a href={props.app_images.member_login_banner_url}><li className="header__menuItem header__menuItemSL">Sign Up</li></a>
            <li className="header__menuItem header__menuItemSL header__menuItemSelected">Members</li>
          </ul>
        ) : (
          <ul className="header__menuItems">
            <a href="/user"><li className="header__menuItem header__menuItemSL">Home</li></a>
            <a href="https://modernrenewablesnj.com/#whatiscommunitysolar"><li className="header__menuItem header__menuItemSL">How Community Solar Works</li></a>
            <a href="https://modernrenewablesnj.com/#aboutus"><li className="header__menuItem header__menuItemSL">About Us</li></a>
            <a href="https://modernrenewablesnj.com/faq/"><li className="header__menuItem header__menuItemSL">FAQ</li></a>
            <a href="https://modernrenewablesnj.com/contact-us/"><li className="header__menuItem header__menuItemSL">Contact Us</li></a>
            <a href="https://solarfarms.formstack.com/forms/njmodernrenewables_api_step1"><li className="header__menuItem header__menuItemSL">Sign Up</li></a>
            <li className="header__menuItem header__menuItemSL header__menuItemSelected">Members</li>
          </ul>
        )}

      </nav>
      {
        props.user &&
        <span className="header__name">
          Welcome: {(props.user.first_name).substring(0, 7)}
        </span>
      }
    </div>
  )
}

export default Header